<template>
  <v-row justify="center">
    <v-dialog v-model="show" max-width="900px">
      <v-card class="d-flex justify-center align-center">
        <v-btn
          @click="show = false"
          class="dialog-close"
          elevation="2"
          fab
          plain
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-img :src="src" :aspect-ratio="16 / 9" contain>
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="grey lighten-1"
              ></v-progress-circular>
            </v-row> </template
        ></v-img>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "Lightbox",
  props: ["value", "src"],
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
        this.$emit("close", value);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.dialog-close {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  z-index: 5;
}
</style>
