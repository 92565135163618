<template>
  <tiptap-vuetify
    v-model="html"
    :extensions="extensions"
    :placeholder="placeholder"
    :card-props="{ color: 'grey lighten-4' }"
    :id="elementId"
    :class="className"
  >
    <template v-slot:toolbar-after>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            class="ml-2"
            v-bind="attrs"
            v-on="on"
            @click="viewSourceCode"
          >
            <v-icon v-if="!showRawHTML">mdi-code-tags</v-icon>
            <v-icon v-else>mdi-subdirectory-arrow-left</v-icon>
          </v-btn>
        </template>
        <span>{{ !showRawHTML ? "View source code" : "Back" }}</span>
      </v-tooltip>
      <v-textarea
        v-if="showRawHTML"
        v-model="html"
        class="html-editor__textarea"
        auto-grow
      ></v-textarea>
    </template>
  </tiptap-vuetify>
</template>

<script>
import _ from "lodash";
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Image,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History
} from "tiptap-vuetify";

export default {
  name: "html-editor",
  components: { TiptapVuetify },
  props: {
    value: {
      type: String,
      default: ""
    },
    className: {
      type: String,
      default: "",
      required: true
    },
    placeholder: {
      type: String,
      default: "",
      required: false
    }
  },
  data() {
    return {
      showRawHTML: false,
      elementId: "",
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        Image,
        BulletList,
        OrderedList,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3, 4, 5, 6]
            }
          }
        ],
        Bold,
        HorizontalRule,
        Paragraph,
        HardBreak
      ]
    };
  },
  mounted() {
    this.value = this.html;
    this.elementId = _.uniqueId("html-editor-");
  },
  methods: {
    viewSourceCode() {
      this.showRawHTML = !this.showRawHTML;
      document
        .querySelector(`#${this.elementId} .tiptap-vuetify-editor__content`)
        .classList.toggle("d-none");
      document
        .querySelector(`#${this.elementId} .v-toolbar__content`)
        .classList.toggle("d-none");
    }
  },
  computed: {
    html: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  }
};
</script>
<style lang="scss">
.tiptap-vuetify-editor__content {
  max-height: 400px;
}

.html-editor__textarea {
  margin: 0 15px;
}

.html-body-error {
  border: 2px solid #ff5252;
  border-radius: 4px;
}

.html-body-error--detail {
  font-size: 0.75rem;
}

.html-icon {
  display: flex;
  align-self: flex-start;
  margin-top: 10px;
}
</style>
