<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-title>
          <div class="d-flex justify-space-between flex-wrap">
            <v-btn
              class="ma-2"
              dark
              color="secondary"
              outlined
              @click="$router.back()"
            >
              <v-icon>mdi-arrow-left</v-icon>
              Back
            </v-btn>
          </div>
        </v-card-title>
        <v-card-title>Information Pages</v-card-title>
        <v-card-title>
          <v-row no-gutter>
            <v-col cols="6" md="4">
              <div>
                <v-text-field
                  :value="information.title"
                  type="text"
                  label="Information"
                  dense
                  readonly
                  disabled
                />
              </div>
              <div v-if="pages.length">
                <div class="label">Pages</div>
                <draggable
                  v-model="pageList"
                  @update="reorderInformationPages"
                  :disabled="loading"
                >
                  <div
                    v-for="element in pages"
                    :key="element.id"
                    class="item-container d-flex align-center font-weight-medium justify-space-between cursor-move w-100"
                    :class="{
                      'selected-item':
                        selectedPage && selectedPage.id === element.id,
                      'disabled-item': loading
                    }"
                    @click="setSelectedPage(element)"
                    @mousedown="setSelectedPage(element)"
                  >
                    <v-icon dense class="item-icon"
                      >mdi-book-open-page-variant-outline</v-icon
                    >
                    <div class="page-title w-full mx-2">
                      {{ element.title }}
                    </div>
                    <v-btn @click="dialog.delete = true" icon color="danger">
                      <v-icon>mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </div>
                </draggable>
              </div>
              <v-btn
                @click="setNewPageState(false)"
                :loading="loading"
                color="primary"
                class="mt-5"
                rounded
                block
              >
                New Page
              </v-btn>
            </v-col>
            <v-col cols="12" sm="6" md="8">
              <div v-if="page">
                <v-form ref="form" lazy-validation>
                  <div>
                    <v-text-field
                      v-model="page.url_title"
                      type="text"
                      label="URL title"
                      placeholder="e.g your-article-url-title"
                      :rules="[rule.required]"
                      outlined
                    />
                  </div>
                  <div>
                    <v-text-field
                      v-model="page.site_title"
                      type="text"
                      label="Site title"
                      outlined
                    />
                  </div>
                  <div>
                    <v-text-field
                      v-model="page.meta_description"
                      type="text"
                      label="Meta description"
                      outlined
                    />
                  </div>
                  <div>
                    <v-text-field
                      v-model="page.meta_keywords"
                      type="text"
                      label="Meta keywords"
                      outlined
                    />
                  </div>
                  <div class="mb-5">
                    <v-tabs v-model="tabs" grow>
                      <v-tabs-slider></v-tabs-slider>
                      <v-tab>Dutch</v-tab>
                      <v-tab>English</v-tab>
                    </v-tabs>
                    <v-divider></v-divider>
                    <v-tabs-items v-model="tabs">
                      <v-tab-item :eager="true">
                        <v-card class="pt-4" flat>
                          <div>
                            <v-text-field
                              v-model="page.title"
                              type="text"
                              label="Title"
                              :rules="[rule.required]"
                              outlined
                            />
                          </div>
                          <div>
                            <v-text-field
                              v-model="page.sub_title"
                              type="text"
                              label="Title sub"
                              :rules="[rule.required]"
                              outlined
                            />
                          </div>
                          <div>
                            <html-editor
                              v-model="page.body"
                              placeholder="Contents"
                              className="page-body"
                            ></html-editor>
                          </div>
                        </v-card>
                      </v-tab-item>
                      <v-tab-item :eager="true">
                        <v-card class="pt-4" flat>
                          <div>
                            <v-text-field
                              v-model="page.title_en"
                              type="text"
                              label="Title"
                              :rules="[rule.required]"
                              outlined
                            />
                          </div>
                          <div>
                            <v-text-field
                              v-model="page.sub_title_en"
                              type="text"
                              label="Title sub"
                              :rules="[rule.required]"
                              outlined
                            />
                          </div>
                          <div>
                            <html-editor
                              v-model="page.body_en"
                              placeholder="Contents"
                              className="page-body"
                            />
                          </div>
                        </v-card>
                      </v-tab-item>
                    </v-tabs-items>
                  </div>
                </v-form>

                <div class="d-flex justify-space-between">
                  <v-row no-gutter>
                    <v-col>
                      <v-btn
                        @click="save"
                        :loading="loading"
                        color="primary"
                        class="w-full"
                      >
                        {{ isCreate ? "Save Page" : "Save Changes" }}
                      </v-btn>
                    </v-col>
                    <v-col>
                      <v-btn
                        @click="resetSelected"
                        :disabled="loading"
                        class="w-full"
                      >
                        Discard
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card-title>
      </base-card>

      <v-dialog v-model="dialog.confirm" max-width="400">
        <v-card v-if="selectedPage" :loading="loading">
          <v-card-title class="text-h6">
            Unsaved changes
          </v-card-title>
          <v-card-text>
            Do you want to save the changes made to
            <b>{{ selectedPage.title }}</b
            >?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="setNewPageState">
              Discard
            </v-btn>
            <v-btn text @click="save(true)" :loading="loading">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialog.delete" max-width="400">
        <v-card v-if="selectedPage" :loading="loading">
          <v-card-title class="text-h6">
            Delete page
          </v-card-title>
          <v-card-text>
            The page <b>{{ selectedPage.title }}</b> will be deleted
            permanently. Are you sure?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="dialog.delete = false">
              Cancel
            </v-btn>
            <v-btn
              color="danger"
              text
              @click="deleteSelectedPage"
              :loading="loading"
            >
              Delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import HtmlEditor from "@/components/HtmlEditor";
import Lightbox from "@/components/Lightbox";
import rule from "@/shared/rules";
import store from "@/store";
import _ from "lodash";
import Draggable from "vuedraggable";
import { mapActions, mapGetters } from "vuex";

export default {
  metaInfo: {
    title: "Information Pages"
  },
  components: { HtmlEditor, Lightbox, Draggable },
  data() {
    return {
      rule,
      id: _.get(this.$route, "params.id"),
      page: null,
      selectedPage: null,
      tabs: null,
      dialog: {
        confirm: false,
        delete: false
      }
    };
  },
  mounted() {},
  methods: {
    ...mapActions([
      "setSelectedInformation",
      "createInformationPage",
      "updateInformationPage",
      "removeInformationPage",
      "reorderInformationPages"
    ]),
    setNewPageState(force = false) {
      if (_.isEqual(this.page, this.defaultPage)) {
        return;
      }

      if (
        this.page &&
        !_.isEqual({ ...this.page }, this.defaultPage) &&
        !force
      ) {
        this.dialog.confirm = true;
        return;
      }

      this.dialog.confirm = false;
      this.resetSelected();
      setTimeout(() => this.setCreatePageState());
    },
    setCreatePageState(page = {}) {
      this.page = {
        ...this.defaultPage,
        ...page
      };
    },
    setSelectedPage(page) {
      this.selectedPage = page;
      this.setCreatePageState(page);
    },
    resetSelected() {
      this.selectedPage = null;
      this.page = null;
    },
    async save(newPage = false) {
      if (!this.$refs.form.validate()) {
        store.dispatch("showSnackbar", "One or more fields are invalid.");
        return;
      }

      if (this.isCreate) {
        await this.createInformationPage(this.page);
        this.resetSelected();
      } else {
        await this.updateInformationPage({
          id: this.page.id,
          payload: _.omit(this.page, ["id", "created_at", "updated_at"])
        });

        if (newPage) {
          this.dialog.confirm = false;
          this.setNewPageState(true);
        }
      }
    },
    async deleteSelectedPage() {
      await this.removeInformationPage(this.selectedPage.id);
      this.resetSelected();
      this.dialog.delete = false;
    }
  },
  computed: {
    ...mapGetters({
      information: "getSelectedInformation",
      pages: "getInformationPages",
      loading: "getInformationPageLoading"
    }),
    pageList: {
      get() {
        return this.pages;
      },
      set(value) {
        this.$store.commit("setInformationPages", value);
      }
    },
    isCreate() {
      return !_.get(this.page, "id");
    },
    defaultPage() {
      return {
        information_id: this.id,
        url_title: null,
        title: null,
        title_en: null,
        sub_title: null,
        sub_title_en: null,
        body: null,
        body_en: null,
        site_title: null,
        meta_keywords: null,
        meta_description: null
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.label {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.38);
}

.item-container {
  padding-left: 8px;
  opacity: 0.5;
  height: 37px;
  border-radius: 20px;

  &.selected-item {
    opacity: 1;
    background-color: #f5f5f5;
  }

  &.disabled-item {
    pointer-events: none !important;
  }

  .page-title {
    font-size: 16px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.page-body {
  max-height: 800px;

  ::v-deep .v-card {
    height: 800px;

    .tiptap-vuetify-editor__content {
      max-height: 690px;
    }
  }
}
</style>
